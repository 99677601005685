import React from "react";
import PropTypes from "prop-types";

class AccordionSection extends React.Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <div
        className="m-10 px-2 py-4 text-left font-sans text-xl  font-semibold text-black md:px-8 md:py-4"
        style={{
          border: "#FB7D8A",
          color: "black",
          fontWeight: "bold",
          borderRadius: "5px",
        }}
      >
        <div className="float-right">
          {!isOpen && (
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          )}
          {isOpen && (
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
              </svg>
            </span>
          )}
        </div>
        <div onClick={onClick} style={{ cursor: "pointer" }}>
          {label}
        </div>
        {isOpen && (
          <div
            className="font-sans text-xl font-normal text-black text-justify"
            style={{
              marginTop: 10,
              padding: "10px 20px",
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
