import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { NavHashLink } from 'react-router-hash-link';

export class Learn extends Component {

  render() {

    return (
      <div className="">
        <Helmet>
          <meta property="og:title" content="PIONEERING A WEB3 GIG ECONOMY" />
          <meta property="og:site_name" content="humanDAO" />
          <meta property="og:image" content="https://res.cloudinary.com/daljbo1q0/image/upload/v1653621760/hdao-nft/hdao_nft_essxni.png" />
          <meta name="description" content="Pioneering a new gig economy on web3" />
          <meta name="twitter:image" content="https://res.cloudinary.com/daljbo1q0/image/upload/v1653621760/hdao-nft/hdao_nft_essxni.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#c5bcff" />
        </Helmet>
        <header className="sticky top-0 z-20">
          <nav class="navbar navbar-expand-lg relative flex w-full items-center justify-between bg-white py-2 shadow-sm">
            <div class="flex w-full flex-wrap items-center justify-between px-6 py-2">
            <div class="logo flex flex-wrap" href="/">
              <a className="mx-auto h-10 w-10 lg:mx-0" rel="noreferrer" href="/">
              <img className="" src="https://cb6757.a2cdn1.secureserver.net/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1668782023"></img>
                </a>
              </div>
              <div class="flex items-center">
                <button
                  class="navbar-toggler mr-2 border-0 bg-transparent py-3 text-xl leading-none text-gray-600 transition-shadow duration-150 ease-in-out hover:text-gray-700 focus:text-gray-700 lg:hidden"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContentY"
                  aria-controls="navbarSupportedContentY"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <div class="flex flex-row md:hidden">
                <a className="mx-auto block items-center rounded-lg bg-[#ef5088] px-3 py-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/pricing">
                  Pricing
                </a>
              </div> */}
              <div class="collapse navbar-collapse grow items-center" id="navbarSupportedContentY">
              <ul class="navbar-nav lg:pl-60 mx-auto  lg:flex lg:flex-row lg:space-x-8">
                  <li class="nav-item">
                    <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" rel="noreferrer" href="/">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      href="/pricing"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      Pricing
                    </a>
                  </li>
                  <li class="nav-item">
                    <NavHashLink
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      to="/#faqs"                      
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      FAQ
                    </NavHashLink>
                  </li>
                  <li class="nav-item mb-2 lg:mb-0">
                    <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" target="_blank" rel="noreferrer" href="https://humandao.org">
                      DAO
                    </a>
                  </li>
                  <li class="nav-item mb-2 lg:mb-0">
                    <a
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      target="_blank"
                      rel="noreferrer"
                      href="https://app.humandao.org/buyhdao"
                    >
                      $HDAO
                    </a>
                  </li>
                </ul>
                <div class="nav-item mb-2 lg:pr-2 pr-0 justify-end lg:mb-0">
                  <a className="block items-center rounded-lg bg-[#ef5088] py-2 pr-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/learnmore">
                    Learn more
                  </a>
                </div>
                <div class="nav-item mb-2 justify-end lg:mb-0">
                  <a className="block items-center rounded-lg bg-white py-2 pr-2 text-base font-bold text-[#ef5088] border-2 border-[#ef5088] hover:bg-[#ef5088] hover:text-white focus:outline-none sm:px-4" rel="noreferrer" href="/trial">
                  Start your free trial
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="to-bggv/60 bg-gradient-to-r from-purple-content/10 px-4 md:px-12">
          <section className="mx-auto place-items-center bg-cover bg-center">
            <div className="mx-auto grid">
              <div className="mx-auto space-y-2 py-8 text-center sm:py-12 md:py-20">
                {/* <img className="mx-auto max-w-[80px] text-center sm:max-w-[100px]" src="https://secureservercdn.net/198.71.233.86/cb6.757.myftpupload.com/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1651670863"></img> */}
                <h1 className="px-12 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-6xl"> Download PDF or book a call</h1>
              </div>
            </div>

            <div class="mx-auto grid max-w-[1200px] gap-4 py-8 pb-20">
              <div class="container mx-auto flex flex-col items-center px-8 md:px-16 py-4 md:flex-row bg-[#dd5b88]/10 rounded-xl shadow-sm">
                <div class="mb-16 flex flex-col items-center text-center md:mb-0 md:w-1/2 md:items-start md:text-left lg:flex-grow order-2">
                  <h2 className="py-2 md:mx-0 text-center font-gilroy text-3xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-left md:text-5xl">Learn more about the platform and its use cases</h2>
                    <div className="flex flex-row py-6 mx-auto md:mx-0">
                      <a
                        className="mx-auto w-[260px] text-center rounded-full bg-accent-purple px-8 py-4 text-lg font-bold text-white shadow-lg transition-all duration-200 hover:shadow-none md:px-10 md:text-xl lg:mx-0"
                        href="/Pocket Assistant Overview Document.pdf" download>
                        Download PDF
                      </a>
                  </div>
                </div>
                <div class="w-5/6 md:w-1/2 lg:w-full lg:max-w-lg order-1">
                  <img class="rounded object-cover object-center" alt="hero" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1669604430/hdao-nft/mail_xmgcbo.png"></img>
                </div>
              </div>
            </div>

            <div class="mx-auto grid max-w-[1200px] gap-4 py-8 pb-20">
              <div class="container mx-auto flex flex-col items-center px-8 md:px-20 py-4 md:flex-row bg-accent-purple/10 rounded-xl shadow-sm">
                <div class="mb-16 flex flex-col items-center text-center md:mb-0 md:w-1/2 md:items-start md:text-left lg:flex-grow order-2 sm:order-1">
                  <h2 className="py-2 md:mx-0 text-center font-gilroy text-3xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-left md:text-5xl">Book a 30-min call</h2>
                    <div className="flex flex-row py-6 mx-auto md:mx-0">
                      <a
                        className="mx-auto w-[260px] text-center rounded-full bg-accent-purple px-8 py-4 text-lg font-bold text-white shadow-lg transition-all duration-200 hover:shadow-none md:px-10 md:text-xl lg:mx-0"
                        href="https://calendly.com/humandao/contact-us"
                        target="_blank"
                      >
                        Schedule
                      </a>
                  </div>
                </div>
                <div class="w-5/6 md:w-1/2 lg:w-full lg:max-w-lg order-1 sm:order-2">
                  <img class="rounded object-cover object-center" alt="hero" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1669219144/hdao-nft/book_a_call_d51hcw.png"></img>
                </div>
              </div>
            </div>
            
          </section>
        </div>
      </div>
    );
  }
}

export default Learn;
