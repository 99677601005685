import React, { Component } from "react";
import Accordion from "./accordion";
import Slider from "react-slick";
import { registerEmail } from "../lib/affiliate";
import StatusDisplay from "../modules/StatusMessage";
import { Helmet } from "react-helmet";
import { NavHashLink } from 'react-router-hash-link';

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      statusMessage: { type: "none", message: "" },
    };
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
  }

  async handleEmailSubmit(event) {
    event.preventDefault();
    let payload = {
      mailing: {
        email: this.state.email,
        category: "panft",
      },
    };
    let result = await registerEmail(payload);
    if (result.success) {
      this.setState({ statusMessage: { type: "success", message: "Your email address was sucessfully submitted." } });
    } else {
      this.setState({ statusMessage: { type: "error", message: "A technical problem occurred when submitting your email. Please, try again later!" } });
    }
  }

  render() {
    const settings = {
      infinite: true,
      autoplay: true,
      arrows: false,
      speed: 1200,
      autoplaySpeed: 3000,
      fade: true,
    };

    return (
      <div>
        <Helmet>
          <meta property="og:title" content="PIONEERING A WEB3 GIG ECONOMY" />
          <meta property="og:site_name" content="humanDAO" />
          <meta property="og:image" content="https://res.cloudinary.com/daljbo1q0/image/upload/v1653621760/hdao-nft/hdao_nft_essxni.png" />
          <meta name="description" content="Pioneering a new gig economy on web3" />
          <meta name="twitter:image" content="https://res.cloudinary.com/daljbo1q0/image/upload/v1653621760/hdao-nft/hdao_nft_essxni.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#c5bcff" />
        </Helmet>
        <header className="sticky top-0 z-20">
          <nav class="navbar navbar-expand-lg relative flex w-full items-center justify-between bg-white py-2 shadow-sm">
            <div class="flex w-full flex-wrap items-center justify-between px-6 py-2">
              <div class="logo flex flex-wrap" href="/">
                <a className="mx-auto h-10 w-10 lg:mx-0" rel="noreferrer" href="/">
                  <img className="" src="https://cb6757.a2cdn1.secureserver.net/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1668782023"></img>
                </a>
              </div>
              <div class="flex items-center">
                <button
                  class="navbar-toggler mr-2 border-0 bg-transparent py-3 text-xl leading-none text-gray-600 transition-shadow duration-150 ease-in-out hover:text-gray-700 focus:text-gray-700 lg:hidden"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContentY"
                  aria-controls="navbarSupportedContentY"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <div class="flex flex-row md:hidden">
                <a className="mx-auto block items-center rounded-lg bg-[#ef5088] px-3 py-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/pricing">
                  Pricing
                </a>
              </div> */}
              <div class="collapse navbar-collapse grow items-center" id="navbarSupportedContentY">
              <ul class="navbar-nav lg:pl-60 mx-auto  lg:flex lg:flex-row lg:space-x-8">
                  <li class="nav-item">
                    <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" rel="noreferrer" href="/">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      href="/pricing"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      Pricing
                    </a>
                  </li>
                  <li class="nav-item">
                    <NavHashLink
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      to="/#faqs"                      
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      FAQ
                    </NavHashLink>
                  </li>
                  <li class="nav-item mb-2 lg:mb-0">
                    <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" target="_blank" rel="noreferrer" href="https://humandao.org">
                      DAO
                    </a>
                  </li>
                  <li class="nav-item mb-2 lg:mb-0">
                    <a
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      target="_blank"
                      rel="noreferrer"
                      href="https://app.humandao.org/buyhdao"
                    >
                      $HDAO
                    </a>
                  </li>
                </ul>
                <div class="nav-item mb-2 lg:pr-2 pr-0 justify-end lg:mb-0">
                  <a className="block items-center rounded-lg bg-[#ef5088] py-2 pr-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/learnmore">
                    Learn more
                  </a>
                </div>
                <div class="nav-item mb-2 justify-end lg:mb-0">
                  <a className="block items-center rounded-lg bg-white py-2 pr-2 text-base font-bold text-[#ef5088] border-2 border-[#ef5088] hover:bg-[#ef5088] hover:text-white focus:outline-none sm:px-4" rel="noreferrer" href="/trial">
                  Start your free trial
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className=" to-bggv/60 bg-gradient-to-r from-purple-content/10">
          <section className="mx-auto h-fit place-items-center bg-cover bg-center sm:max-w-[1500px]">
            <div className="mx-auto grid">
              <div className="mx-auto space-y-2 py-8 md:py-16">
                <img className="mx-auto max-w-[250px] text-center sm:max-w-[300px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1660621598/hdao-nft/NFT_uomtba.png"></img>
                <h1 className="stroke-black px-12 text-center font-gilroy text-5xl uppercase tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-6xl">Pocket Assistant NFT</h1>
                <p className="px-24 text-center font-inter text-2xl font-bold text-black sm:px-6 md:text-4xl">Pioneering a web3 gig economy</p>
                <div className="mx-auto grid grid-cols-1 space-y-4 py-8">
                  <a
                    className="mx-auto w-[275px] rounded-full bg-accent-purple px-8 py-4 text-base font-bold text-white shadow-lg transition-all duration-200 hover:shadow-none md:px-10 md:text-xl"
                    rel="noreferrer"
                    href="/learnmore"
                  >
                    Learn more
                  </a>
                  <a
                    className="mx-auto w-[275px] rounded-full bg-white px-8 py-4 text-base font-bold text-accent-purple shadow-lg transition-all duration-200 hover:shadow-none md:px-10 md:text-xl"
                    rel="noreferrer"
                    href="/trial"
                  >
                    Start your free trial
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section class="features-1 mx-4 my-12 max-w-[1300px] md:mx-auto md:px-2 xl:px-0">
            <div className="grid grid-cols-1 lg:space-x-2 lg:grid-cols-2 space-y-2 lg:space-y-0">
              <div className="rounded-xl bg-accent-purple/10 py-8 text-center shadow-sm xl:text-left">
                <h2 className="text-center font-gilroy px-2 text-3xl tracking-normal text-[#dd5b88] xl:text-3xl ">Enable opportunities for the underserved</h2>
                <img className="mx-auto w-[200px] py-4 md:w-[300px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655401277/hdao-nft/39_fiahck.png"></img>
              </div>
              <div className="rounded-xl bg-accent-purple/10 py-8 px-2 text-center shadow-sm xl:text-left">
                <h2 className="text-center font-gilroy text-3xl tracking-normal text-[#dd5b88] xl:text-3xl ">Save time and do more of what you love</h2>
                <img className="mx-auto w-[200px] py-4 md:w-[300px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1669826861/hdao-nft/My_project-1_1_f25idp.png"></img>
              </div>
            </div>
          </section>

          <section class="features-1 mx-4 my-12 max-w-[1300px] md:mx-auto md:px-2 xl:px-0">
            <div className="rounded-xl text-center">
            <h1 className="py-2 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-5xl">Pocket Assistant lets you do both</h1>
              <img className="mx-auto w-[400px] py-4 md:w-[600px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1669207820/hdao-nft/PANFT_Card_junfpx.png"></img>
              <div class="-mb-4 flex flex-col md:flex-row text-center mx-auto lg:space-x-1">
              <div class="mb-10 flex flex-col items-center lg:items-start w-full lg:w-1/2 px-6 py-6 rounded-lg">
              <div class="mb-5 inline-flex h-20 w-20 flex-shrink-0 mx-auto items-center justify-center text-accent-purple">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                      />
                    </svg>
                  </div>
                  <div class="flex-grow">
                    <p class="text-lg font-medium text-justify font-inter leading-relaxed">PANFT grants you access to a pool of assistants who can complete time-consuming tasks.</p>
                  </div>
                </div>
                <div class="mb-10 flex flex-col items-center lg:items-start w-full lg:w-1/2 px-6 py-6">
                <div class="mb-5 inline-flex h-20 w-20 flex-shrink-0 mx-auto items-center justify-center text-accent-purple">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                    </svg>
                  </div>
                  <div class="flex-grow">
                  <p class="text-lg font-medium text-justify font-inter leading-relaxed">In doing so, we can provide valuable, safe, and flexible access to income in underserved communities.</p>
                  </div>
                </div>
                <div class="flex flex-col items-center lg:items-start w-full lg:w-1/2 px-6 py-6">
                <div class="mb-5 inline-flex h-20 w-20 flex-shrink-0 mx-auto items-center justify-center text-accent-purple">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-16 w-16">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                      />
                    </svg>
                  </div>
                  <div class="flex-grow">
                    <p class="text-lg font-medium text-justify font-inter leading-relaxed">An instant productivity boost for the busy individual, employee, project, company, or DAO.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="features-1 mx-4 md:mx-auto my-12 grid max-w-[1300px] gap-4 rounded-xl bg-accent-purple/10 py-8  shadow-sm md:px-2 xl:px-0">
            <div id="assistants" className="container  mx-auto flex flex-col items-center px-5 py-12 md:flex-row lg:px-5 lg:pt-24">
              <div className="mx-auto max-w-xs md:max-w-5xl lg:ml-4 lg:w-full lg:max-w-md">
                <img className="left-0 h-full w-full object-contain" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653369892/hdao-nft/18_ta8pvr.png" alt="heart"></img>
              </div>
              <div className="md:2/3 mt-auto mb-auto flex flex-wrap content-start lg:w-2/3">
                <div className="mb-6 w-full text-left sm:p-8">
                  <h1 className="py-2 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-left md:text-5xl">Uniquely Human NFTs</h1>
                  <p className="lg:text-md mt-4 text-justify font-inter  text-lg text-black md:text-left lg:text-left">
                  We believe you can grow your impact on the world while helping improve the lives of others. Assistants are from marginalized communities who earn by completing tasks. Through the PA platform we help those at the top of the economic ladder as well as those at the bottom through a novel service facilitated by NFTs. With a PANFT in your wallet you can create space in your day or be more productive, all while improving the lives of others. 
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="features-2 mx-4 md:mx-auto my-12 grid max-w-[1300px] gap-4 rounded-xl bg-accent-purple/10 py-8  shadow-sm md:px-2 xl:px-0">
            <div className="container  mx-auto flex flex-col items-center px-5 py-12 md:flex-row lg:px-14 lg:pb-12">
              <div className="order-last mt-auto mb-auto flex flex-wrap content-start md:order-none md:w-full lg:w-2/3">
                <div className=" mb-6 w-full text-center">
                  <h1 className="py-2 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-left md:text-5xl">Real utility</h1>
                  <p className="lg:text-md mt-6 text-justify font-inter  text-lg text-black md:text-left lg:text-left">
                    The NFT grants the holder a certain amount of energy per month to delegate tasks to the PA pool. 1 energy = 1 task = up to 30 minutes of Assistant time. Saving you hours a day. Below you will find example
                    tasks.
                  </p>
                </div>
              </div>
              <div className="order-first mx-4 md:mx-auto w-[220px] md:order-none md:w-[350px] md:pt-12 lg:w-full lg:max-w-xs">
                <img className="h-full w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655789504/hdao-nft/19_yhdazc.png" alt="lightning with clock"></img>
              </div>
            </div>
          </section>

          <section className="tasks body-font mx-auto max-w-[1000px] text-gray-600 md:max-w-[1500px]">
            <div className="flex w-full flex-col text-center">
              <h1 className="py-8 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-5xl">What can you get done?</h1>
              <div className="mx-auto grid grid-cols-1 justify-center gap-8 sm:grid-cols-2 xl:grid-cols-4 xl:gap-8">
                <div class="flip-card h-[350px] w-[300px]">
                  <div class="flip-card-inner relative rounded-lg duration-700">
                    <div class="flip-card-front mx-auto space-y-6 rounded-lg px-4 py-28 text-center shadow-md">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="mx-auto h-20 w-20">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>
                      <h3 class="font-inter text-3xl font-bold leading-relaxed text-white">Personal Tasks</h3>
                      <div className="justify-right absolute bottom-0 right-0 flex flex-row p-4 font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-6 w-6 pr-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                        </svg>
                        <p>See tasks</p>
                      </div>
                    </div>
                    <div class="flip-card-back wordwrap rounded-lg py-2 px-4 text-center text-sm font-semibold shadow-sm">
                      <ul className="list-disc p-4 text-left">
                        <li className="py-2 text-white">Research flights and hotels</li>
                        <li className="py-2 text-white">Price shop best offers for a product or service</li>
                        <li className="py-2 text-white">Event planning research</li>
                        <li className="py-2 text-white">Confirm appointments</li>
                        <li className="py-2 text-white">Research transportation options</li>
                        <li className="py-2 text-white">Collate and file expenses into a spreadsheet</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flip-card h-[350px] w-[300px]">
                  <div class="flip-card-inner relative rounded-lg duration-700">
                    <div class="flip-card-front mx-auto space-y-6 rounded-lg px-4 py-28 text-center shadow-md">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="mx-auto h-20 w-20">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                        />
                      </svg>
                      <h3 class="font-inter text-3xl font-bold leading-relaxed text-white">Administration</h3>
                      <div className="justify-right absolute bottom-0 right-0 flex flex-row p-4 font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-6 w-6 pr-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                        </svg>
                        <p>See tasks</p>
                      </div>
                    </div>
                    <div class="flip-card-back  rounded-lg py-2 px-5 text-center text-sm font-semibold shadow-sm">
                      <ul className="list-disc p-4 text-left">
                        <li className="py-2 text-white">Add accounts to a spreadsheet/CRM</li>
                        <li className="py-2 text-white">Find businesses or peoples emails or contact info</li>
                        <li className="py-2 text-white">Search and find 10 particular images online for use</li>
                        <li className="py-2 text-white">Transcribe audio or notes from a webinar</li>
                        <li className="py-2 text-white">Create/update spreadsheets</li>
                        <li className="py-2 text-white">Verify outdated data, update changes to records</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flip-card h-[350px] w-[300px]">
                  <div class="flip-card-inner relative rounded-lg duration-700">
                    <div class="flip-card-front mx-auto space-y-6 rounded-lg px-4 py-28 text-center shadow-md">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="mx-auto h-20 w-20">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59"
                        />
                      </svg>
                      <h3 class="font-inter text-3xl font-bold leading-relaxed text-white">Lead Generation</h3>
                      <div className="justify-right absolute bottom-0 right-0 flex flex-row p-4 font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-6 w-6 pr-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                        </svg>
                        <p>See tasks</p>
                      </div>
                    </div>
                    <div class="flip-card-back  rounded-lg py-2 px-5 text-center text-sm font-semibold shadow-sm">
                      <ul className="list-disc p-4 text-left">
                        <li className="py-2 text-white">Find leads on social media and/or job board sites</li>
                        <li className="py-2 text-white">Call stores/businesses to get information</li>
                        <li className="py-2 text-white">Retrieve public records/addresses/contact details</li>
                        <li className="py-2 text-white">Find and list popular channels and podcasts on P2E gaming</li>
                        <li className="py-2 text-white">Make a list of keywords to search for a specific product or service</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flip-card h-[350px] w-[300px]">
                  <div class="flip-card-inner relative rounded-lg duration-700">
                    <div class="flip-card-front mx-auto space-y-6 rounded-lg px-4 py-28 text-center shadow-md">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="mx-auto h-20 w-20">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 7.5l-2.25-1.313M21 7.5v2.25m0-2.25l-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3l2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75l2.25-1.313M12 21.75V19.5m0 2.25l-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                        />
                      </svg>
                      <h3 class="font-inter text-3xl font-bold leading-relaxed text-white">Web3 Research</h3>
                      <div className="justify-right absolute bottom-0 right-0 flex flex-row p-4 font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="h-6 w-6 pr-2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                        </svg>
                        <p>See tasks</p>
                      </div>
                    </div>
                    <div class="flip-card-back  rounded-lg py-2 px-5 text-center text-sm font-semibold shadow-sm">
                      <ul className="list-disc p-4 text-left">
                        <li className="py-2 text-white">Search and link to the roadmap of these 5 projects</li>
                        <li className="py-2 text-white">Research and collect certain data points over time</li>
                        <li className="py-2 text-white">Find and link me to the blogs for these 10 projects</li>
                        <li className="py-2 text-white">Find and link to Discord servers for these 10 projects</li>
                        <li className="py-2 text-white">Who are the investors (VCs) in xyz company</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="features-3 mx-4 md:mx-auto my-12 grid max-w-[1300px] gap-4 rounded-xl bg-accent-purple/10 py-8  shadow-sm md:px-2 xl:px-0">
            <div className="container mx-auto flex flex-col items-center px-5 py-12 lg:flex-row">
              <div className="mx-auto max-w-xs md:max-w-5xl lg:ml-4 lg:w-full lg:max-w-md">
                <img className="h-full w-full max-w-md object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/21_tdndfg.png" alt="lightning with clock"></img>
              </div>
              <div className="md:2/3 mt-auto mb-auto flex flex-wrap content-start lg:w-2/3">
                <div className="mb-6 w-full text-left sm:p-8">
                  <h1 className="py-2 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-5xl lg:text-left">High-value focus</h1>
                  <p className="lg:text-md mt-6 text-justify font-inter  text-lg text-black md:text-left lg:text-left">
                    To spend more time on high-value activities, spend less time on lower-value activities. Three lists that will help boost productivity with your PANFT:{" "}
                  </p>
                  <div className="lg:order-0 order-1">
                    <div className="w-full lg:max-w-md">
                      <ul className="align-left grid grid-cols-1">
                        <li className="inline-flex space-x-4 py-2 lg:text-left xl:py-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-black md:h-10 md:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="flex-row text-left text-lg font-medium text-black md:text-xl">Things you hate doing</span>
                        </li>
                        <li className="inline-flex space-x-4 py-2 lg:text-left xl:py-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-black md:h-10 md:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="inline-flex text-lg font-medium text-black md:text-xl">Things you can't do yourself</span>
                        </li>
                        <li className="inline-flex space-x-4 py-2 lg:text-left xl:py-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-black md:h-10 md:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="flex-row text-lg font-medium text-black md:text-xl">Things you shouldn't be doing</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="focus body-font mx-auto text-gray-600 sm:max-w-[1500px]">
            <div className="container mx-auto flex flex-col items-center px-5 py-12 lg:flex-row">
              <div className="mx-auto max-w-xs md:max-w-5xl lg:ml-4 lg:w-full lg:max-w-md">
                <img className="h-full w-full max-w-md object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/21_tdndfg.png" alt="lightning with clock"></img>
              </div>
              <div className="md:2/3 mt-auto mb-auto flex flex-wrap content-start lg:w-2/3">
                <div className="mb-6 w-full text-left sm:p-8">
                  <h1 className="py-2 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-5xl lg:text-left">High-value focus</h1>
                  <p className="lg:text-md mt-6 text-justify font-inter  text-lg text-black md:text-left lg:text-left">
                    To spend more time on high-value activities, spend less time on lower-value activities. Make 3 lists that will help you boost productivity with your PANFT:{" "}
                  </p>
                  <div className="lg:order-0 order-1">
                    <div className="w-full lg:max-w-md">
                      <ul className="align-left grid grid-cols-1">
                        <li className="inline-flex space-x-4 py-2 lg:text-left xl:py-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-black md:h-10 md:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="flex-row text-left text-lg font-medium text-black md:text-xl">Things you hate doing</span>
                        </li>
                        <li className="inline-flex space-x-4 py-2 lg:text-left xl:py-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-black md:h-10 md:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="inline-flex text-lg font-medium text-black md:text-xl">Things you can't do yourself</span>
                        </li>
                        <li className="inline-flex space-x-4 py-2 lg:text-left xl:py-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-row text-black md:h-10 md:w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="flex-row text-lg font-medium text-black md:text-xl">Things you shouldn't be doing</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <section className="benefits body-font mx-auto text-gray-600 sm:max-w-[1300px] px-4 xl:px-0">
            <div className="container mx-auto pb-12">
              <div className="flex w-full flex-col text-center xl:px-20">
                <h1 className="py-8 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-5xl">The most functional NFT you'll own</h1>
              </div>
              <div className="mx-auto flex flex-col justify-center space-y-3 lg:flex-row lg:space-x-5 lg:space-y-0">
                <div className="md:1/2 rounded-xl bg-accent-purple/10 p-4 shadow-sm lg:w-[420px]">
                  <div className="flex h-full flex-col rounded-lg p-8">
                    <div className="mx-auto flex items-center">
                      <img className="sm:1/2 mx-auto w-full md:w-[300px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625196/hdao-nft/27_baciv3.png"></img>
                    </div>
                    <div className="flex-grow">
                      <p className=" font-inter text-2xl font-semibold text-black">Take charge of time</p>
                      <p className="px-8 text-center font-inter text-lg leading-tight text-black sm:px-28 lg:px-0">Do more of what you love by delegating low-value tasks, all at the touch of a button.</p>
                    </div>
                  </div>
                </div>
                <div className="md:1/2 rounded-xl bg-accent-purple/10 p-4 shadow-sm lg:w-[420px]">
                  <div className="flex h-full flex-col rounded-lg p-8">
                    <div className="mx-auto flex items-center">
                      <img className="sm:1/2 mx-auto w-full md:w-[300px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625196/hdao-nft/26_gnscxi.png"></img>
                    </div>
                    <div className="flex-grow">
                      <p className=" font-inter text-2xl font-semibold text-black">Share energy</p>
                      <p className="px-8 text-center font-inter text-lg leading-tight text-black sm:px-28 lg:px-0">
                        Give the gift of time to friends, family, and colleagues, or split the cost and share the benefits. Your PANFT is as flexible as it is useful.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:1/2 rounded-xl bg-accent-purple/10 p-4 shadow-sm lg:w-[420px]">
                  <div className="flex h-full flex-col rounded-lg p-8">
                    <div className="mx-auto flex items-center">
                      <img className="sm:1/2 mx-auto w-full md:w-[300px]" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1652625195/hdao-nft/22_rekqjr.png"></img>
                    </div>
                    <div className="flex-grow">
                      <p className="font-inter text-2xl font-semibold text-black ">Empower people</p>
                      <p className="px-4 text-center font-inter text-lg leading-tight text-black sm:px-28 lg:px-0">
                        Support humanity. By fulfilling your, tasks Assistants can make more than a competitive wage compared to local opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="form body-font  mx-auto text-gray-600 sm:max-w-[1500px]">
            <div className="container mx-auto flex flex-col items-center px-5 pt-7 md:pt-11 lg:flex-row lg:pb-11">
              <div className="order-last mx-auto w-full sm:max-w-[450px] md:order-none md:w-2/3 md:p-2 lg:mt-0 lg:max-w-lg">
                <Slider {...settings}>
                  <div className="mx-auto w-5/6 animate-fade-in-down ease-in md:w-2/4 md:p-2 lg:mt-2">
                    <img className="w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1653316999/hdao-nft/28_i46bhc.png" alt="commmunity"></img>
                  </div>
                  <div className="mx-auto w-5/6 animate-fade-in-down md:mt-24 md:w-2/4 md:p-2 lg:mt-0">
                    <img className="w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1655790693/hdao-nft/1_fdqd59_wi3bgk.png" alt="person arranging blocks"></img>
                  </div>
                  <div className="mx-auto w-5/6 animate-fade-in-down md:w-2/4 md:p-2 lg:mt-0">
                    <img className="w-full object-contain object-center" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1651672451/hdao-pa/11_ufniuj.png" alt="two people using phone"></img>
                  </div>
                </Slider>{" "}
              </div>
              <div className="mx-auto items-center py-6 text-center sm:max-w-[1500px]">
                <h1 className="py-4 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-left md:text-5xl">Ready to get more done?</h1>
                <div className="mx-auto flex lg:flex-row lg:text-left">
                  <a className="mx-auto w-fit rounded-full bg-accent-purple px-8 py-4 text-base font-bold text-white shadow-lg transition-all duration-200 hover:shadow-none md:px-10 md:text-xl lg:mx-0" href="pricing">
                    See pricing
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section id="faqs" className="FAQs relative mx-auto pt-12 pb-12 sm:max-w-[1500px]">
            <div className="align-center container mx-auto px-8 xl:px-5">
              <h1 className="px-12 py-12 text-center font-gilroy text-4xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-5xl">Frequently Asked Questions</h1>
            </div>
            <div className="mx-auto w-full py-6 pt-4 text-center text-xs sm:px-8 md:px-12 lg:w-5/6 xl:w-2/3">
              <Accordion>
                <div className="duration-300 ease-in" label="Is this a personal assistant?">
                  <p>No. All tasks go to a pool of available Assistants. You will have many Assistants fulfilling your acceptable tasks, not the same one.</p>
                </div>
                <div label="What are acceptable tasks?">
                  <p>
                    As the examples above show, pretty much anything beginning with Find, Collect, Make, Add, or Search. You will need to be specific in your request, and you will not have the same Assistant for all your tasks.
                    Thus, tasks requiring privileged info (email, Amazon, bank logins) will not work. Stick to research and data entry, and you will be fine. We are looking to expand acceptable tasks in the future as we
                    grow and iterate.
                  </p>
                </div>
                <div label="Where do these Assistants come from?">
                  <p>
                    Our community is global and comes from low economic backgrounds but is connected to the internet, ambitious and hungry for an opportunity to earn. Thus far, most Assistants are woman, come from Nigeria, the
                    Philippines, and S. America and are proficient in speaking English.
                  </p>
                  <p className="pt-4">Being an Assistant is like being an Uber driver or freelancer because it is gig work. They can work safely from their internet-connected devices around their schedules.</p>
                  <p className="pt-4">
                    Assistants are “qualified,” signifying they must pass our exam to learn or improve on the skills required to become effective at this role. The more proficient they become, the more tasks they can accomplish
                    - the more money they can make. It is important that anyone from anywhere can become an Assistant. So we provide free learning materials and resources (shout to Google) for novices to masters. They can learn
                    these skills in a week or two.
                  </p>
                  <p className="pt-4">
                    Join our community ​
                    <span>
                      <a className="underline hover:text-[#ef5088]" href="https://discord.com/invite/humandao" target="_blank" rel="noreferrer">
                        here
                      </a>
                    </span>
                    .{" "}
                  </p>
                  <p className="pt-4">
                    Learn about us ​
                    <span className="underline hover:text-[#ef5088]">
                      <a href="https://docs.humandao.org" target="_blank" rel="noreferrer">
                        here
                      </a>
                    </span>
                    .
                  </p>
                </div>
                <div label="How is this different from Fiverr?">
                  <p className="italic">From the buyer's perspective</p>
                  <ul className="mx-auto list-disc pt-6 pl-4">
                    <li>Don’t have to spend time finding the right person every time</li>
                    <li>Don’t have to pay per task/job</li>
                    <li>Can resell because NFT</li>
                    <li>Can make money through the rental market</li>
                    <li>Complete privacy, only validates holder</li>
                    <li>Can share your access to the service</li>
                  </ul>
                  <p className="pt-6 italic">From the worker's perspective</p>
                  <ul className="mx-auto list-disc pt-6 pl-4">
                    <li>No need to undercut competitors</li>
                    <li>Spend time gaining customers/ building up reviews</li>
                    <li>Login and tasks are waiting</li>
                    <li>20% get 80% of jobs on Fiverr. Earlier adopters dominate, not so with PANFT</li>
                  </ul>
                </div>
                {/* <div label="Can I roll over my energy?">
                  <p>
                    Energy not used does NOT roll over to the following week. But your 20 energies will reload every week (as long as HDAO is staked). Remember, you only get 20 energy/tasks per week.
                  </p>
                </div> */}
              </Accordion>
            </div>
          </section>
          <section className="contact pb-12">
            <div className="mx-auto flex w-full max-w-[900px] flex-wrap px-5">
              <div className="shadow-circle min-w-[250px] flex-1 overflow-hidden rounded-lg bg-slate-300/30 px-5 pt-8 pb-8 text-center drop-shadow-sm">
                <h3 className="mb-2 text-3xl font-bold leading-none text-black">Stay updated on PANFT</h3>
                <form onSubmit={this.handleEmailSubmit}>
                  <input
                    required
                    placeholder="Email address"
                    type="email"
                    className=" mt-4 w-2/3 rounded-md bg-slate-50 px-4 py-4 text-center shadow-sm"
                    onChange={(event) => this.setState({ email: event.target.value })}
                  ></input>
                  <input
                    type="submit"
                    className="mx-auto mt-8 block w-fit rounded-full bg-[#6655c4] px-8 py-4 text-base font-bold text-white shadow-lg transition-all duration-200 hover:cursor-pointer hover:shadow-none sm:px-10 md:text-xl"
                    value="Notify me"
                  ></input>
                  <StatusDisplay statusMessage={this.state.statusMessage} resetStatus={() => this.setState({ statusMessage: { type: "none", message: "" } })}></StatusDisplay>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Content;
