import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';


const Trial = () => {
  const [result, setResult] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const navigate = useNavigate();
  const [val, setVal]=useState('');
  const leads=["Twitter", "LinkedIn", "Discord", "Facebook", "Medium", "Gmail", "Outlook", "Google"]
  const purpose=["Personal tasks", "Administration", "Research", "Lead Generation"]


  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Success! You will now be redirected to the homepage.");
    setTimeout(() => {
    navigate("/");
    },5000);
    const formData = new FormData(event.target);

    formData.append("access_key", "27b3ba70-6200-4c43-ad26-3b728a8cb012");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
    if (res.success) {
      setResult(res.message);
    } else {
      setResult(res.message);
    }
  };

  return (
    <div className="Trial">
    <header className="sticky top-0 z-20">
        <nav className="navbar navbar-expand-lg relative flex w-full items-center justify-between bg-white py-2 shadow-sm">
        <div className="flex w-full flex-wrap items-center justify-between px-6 py-2">
        <div className="logo flex flex-wrap" href="/">
            <a className="mx-auto h-10 w-10 lg:mx-0" rel="noreferrer" href="/">
            <img className="" src="https://cb6757.a2cdn1.secureserver.net/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1668782023"></img>
            </a>
            </div>
            <div className="flex items-center">
            <button
                className="navbar-toggler mr-2 border-0 bg-transparent py-3 text-xl leading-none text-gray-600 transition-shadow duration-150 ease-in-out hover:text-gray-700 focus:text-gray-700 lg:hidden"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContentY"
                aria-controls="navbarSupportedContentY"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    fill="currentColor"
                    d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
                </svg>
            </button>
            </div>
            <div className="collapse navbar-collapse grow items-center" id="navbarSupportedContentY">
            <ul class="navbar-nav lg:pl-60 mx-auto  lg:flex lg:flex-row lg:space-x-8">
                <li className="nav-item">
                <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" rel="noreferrer" href="/">
                    Home
                </a>
                </li>
                <li className="nav-item">
                <a
                    className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                    href="/pricing"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                >
                    Pricing
                </a>
                </li>
                <li class="nav-item">
                    <NavHashLink
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      to="/#faqs"                      
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      FAQ
                    </NavHashLink>
                  </li>
                <li className="nav-item mb-2 lg:mb-0">
                <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" target="_blank" rel="noreferrer" href="https://humandao.org">
                    DAO
                </a>
                </li>
                <li className="nav-item mb-2 lg:mb-0">
                <a
                    className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                    target="_blank"
                    rel="noreferrer"
                    href="https://app.humandao.org/buyhdao"
                >
                    $HDAO
                </a>
                </li>
            </ul>
            <div class="nav-item mb-2 lg:pr-2 pr-0 justify-end lg:mb-0">
                <a className="block items-center rounded-lg bg-[#ef5088] py-2 pr-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/learnmore">
                Learn more
                </a>
            </div>
            <div className="nav-item mb-2 justify-end lg:mb-0">
                <a className="block items-center rounded-lg bg-white py-2 pr-2 text-base font-bold text-[#ef5088] border-2 border-[#ef5088] hover:bg-[#ef5088] hover:text-white focus:outline-none sm:px-4" rel="noreferrer" href="/trial">
                Start your free trial
                </a>
            </div>
            </div>
        </div>
        </nav>
    </header>
    <div className="flex items-center min-h-screen to-bggv/60 bg-gradient-to-r from-purple-content/10">
    <div className="container mx-auto">
        <div className="max-w-lg mx-auto my-10 bg-white p-5 rounded-md shadow-sm">
        <div className="text-center">
            <h1 className="my-3 text-3xl font-semibold text-bold font-inter">
            Sign up for a free trial
            </h1>
            <p className="text-black dark:text-black">
            Enter your contact details and we will be in touch soon.
            </p>
        </div>
        <div className="m-7">
            <form onSubmit={onSubmit}>
            <input type="hidden" name="access_key" value="27b3ba70-6200-4c43-ad26-3b728a8cb012" />
            <input type="hidden" name="subject" value="New Submission from Web3Forms" />
            <input type="hidden" name="redirect" value="https://web3forms.com/success" />
            <div className="mb-6">
                <label htmlFor="name" className="block mb-2 text-sm text-black font-bold">Name</label>
                <input required type="text" name="name" id="name" placeholder="John Doe" className="w-full rounded-md bg-slate-100 px-4 py-4 text-center shadow-sm" />
            </div>
            <div className="mb-6">
                <label htmlFor="email" className="block mb-2 text-sm text-black font-bold">Email Address</label>
                <input required type="email" name="email" id="email" placeholder="you@company.com" className="w-full rounded-md bg-slate-100 px-4 py-4 text-center shadow-sm" />
            </div>
            <div className="mb-6">
                <label htmlFor="select" className="block mb-2 text-sm text-black font-bold">What do you plan to use PANFT for?</label>
                <input required list="purpose" onChange={(e)=>setVal(e.target.value)} type="text" name="purpose" placeholder="Research, administration, etc." className="w-full rounded-md bg-slate-100 px-4 py-4 text-center shadow-sm" />
                <datalist id="purpose">
                {purpose.map((op)=><option>{op}</option>)}
                <p>{val}</p>
                </datalist>
            </div>
            <div className="mb-6">
                <label htmlFor="select" className="block mb-2 text-sm text-black font-bold">Where did you hear about us?</label>
                <input required list="leads" type="text" name="leads" onChange={(e)=>setVal(e.target.value)} placeholder="Twitter, Discord server, etc." className="w-full rounded-md bg-slate-100 px-4 py-4 text-center shadow-sm" />
                <datalist id="leads">
                {leads.map((op)=><option>{op}</option>)}
                <p>{val}</p>
                </datalist>
            </div>
            <div className="mb-6">
                <label htmlFor="message" className="block mb-2 text-sm text-black font-bold">Do you have any questions for us?</label>
                <textarea name="message" id="message" placeholder="Your Message" className="w-full rounded-md bg-slate-100 px-4 py-4 text-center shadow-sm" required></textarea>
            </div>
            <div className={"mb-6" + isSubmitted? " " : " hidden "} onClick={()=> {setIsSubmitted(true)}}>
            <input className={"w-full items-center rounded-lg bg-[#ef5088] py-2 pr-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none" } type="submit" value="Submit"/>
            </div>
            </form>
        </div>
        {result !==""? 
          <span>
          <div class="flex flex-col justify-center">
          <div class="bg-green-500 shadow-lg mx-auto max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3" id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">
              <div class="bg-green-500 flex justify-between items-center py-2 px-3 border-b border-green-400 rounded-md">
              <p class="font-bold text-white flex items-center">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
                  </svg>
                  {result}</p>
              </div>
          </div>
          </div>
          </span>
          :
          <></>
        }
        </div>
    </div>
    </div>
    </div>
  );
}

export default Trial;