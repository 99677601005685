import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { NavHashLink } from 'react-router-hash-link';

export class Pricing extends Component {

  render() {

    return (
      <div className="">
        <Helmet>
          <meta property="og:title" content="PIONEERING A WEB3 GIG ECONOMY" />
          <meta property="og:site_name" content="humanDAO" />
          <meta property="og:image" content="https://res.cloudinary.com/daljbo1q0/image/upload/v1653621760/hdao-nft/hdao_nft_essxni.png" />
          <meta name="description" content="Pioneering a new gig economy on web3" />
          <meta name="twitter:image" content="https://res.cloudinary.com/daljbo1q0/image/upload/v1653621760/hdao-nft/hdao_nft_essxni.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#c5bcff" />
        </Helmet>
        <header className="sticky top-0 z-20">
          <nav class="navbar navbar-expand-lg relative flex w-full items-center justify-between bg-white py-2 shadow-sm">
            <div class="flex w-full flex-wrap items-center justify-between px-6 py-2">
            <div class="logo flex flex-wrap" href="/">
              <a className="mx-auto h-10 w-10 lg:mx-0" rel="noreferrer" href="/">
              <img className="" src="https://cb6757.a2cdn1.secureserver.net/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1668782023"></img>
                </a>
              </div>
              <div class="flex items-center">
                <button
                  class="navbar-toggler mr-2 border-0 bg-transparent py-3 text-xl leading-none text-gray-600 transition-shadow duration-150 ease-in-out hover:text-gray-700 focus:text-gray-700 lg:hidden"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContentY"
                  aria-controls="navbarSupportedContentY"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="currentColor"
                      d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <div class="flex flex-row md:hidden">
                <a className="mx-auto block items-center rounded-lg bg-[#ef5088] px-3 py-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/pricing">
                  Pricing
                </a>
              </div> */}
              <div class="collapse navbar-collapse grow items-center" id="navbarSupportedContentY">
              <ul class="navbar-nav lg:pl-60 mx-auto  lg:flex lg:flex-row lg:space-x-8">
                  <li class="nav-item">
                    <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" rel="noreferrer" href="/">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      href="/pricing"
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      Pricing
                    </a>
                  </li>
                  <li class="nav-item">
                    <NavHashLink
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      to="/#faqs"                      
                      data-mdb-ripple="true"
                      data-mdb-ripple-color="light"
                    >
                      FAQ
                    </NavHashLink>
                  </li>
                  <li class="nav-item mb-2 lg:mb-0">
                    <a className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4" target="_blank" rel="noreferrer" href="https://humandao.org">
                      DAO
                    </a>
                  </li>
                  <li class="nav-item mb-2 lg:mb-0">
                    <a
                      className="block items-center rounded-lg py-2 pr-2 text-base font-bold text-black hover:text-[#ef5088] focus:outline-none sm:px-4"
                      target="_blank"
                      rel="noreferrer"
                      href="https://app.humandao.org/buyhdao"
                    >
                      $HDAO
                    </a>
                  </li>
                </ul>
                <div class="nav-item mb-2 lg:pr-2 pr-0 justify-end lg:mb-0">
                  <a className="block items-center rounded-lg bg-[#ef5088] py-2 pr-2 text-base font-bold text-white hover:bg-[#d64477] focus:outline-none sm:px-4" rel="noreferrer" href="/learnmore">
                    Learn more
                  </a>
                </div>
                <div className="nav-item mb-2 justify-end lg:mb-0">
                <a className="block items-center rounded-lg bg-white py-2 text-base font-bold text-[#ef5088] border-2 border-[#ef5088] hover:bg-[#ef5088] hover:text-white focus:outline-none sm:px-4" rel="noreferrer" href="/trial">
                Start your free trial
                </a>
            </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="to-bggv/60 bg-gradient-to-r from-purple-content/10 px-4 md:px-12">
          <section className="mx-auto place-items-center bg-cover bg-center">
            <div className="mx-auto grid">
              <div className="mx-auto space-y-2 py-8 text-center sm:py-12 md:py-20">
                {/* <img className="mx-auto max-w-[80px] text-center sm:max-w-[100px]" src="https://secureservercdn.net/198.71.233.86/cb6.757.myftpupload.com/wp-content/uploads/2021/12/HDAO-60x60-72dpi-1.svg?time=1651670863"></img> */}
                <h1 className="px-12 text-center font-gilroy text-5xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-7xl">Pricing</h1>
                <p className="px-2 text-center font-inter text-base font-medium text-black sm:px-4 md:text-2xl">
                  Pocket Assistant NFTs are on-chain memberships available through monthly subscription.
                </p>
                <p className="pt-2 md:pt-0 px-2 text-center font-inter text-base font-semibold text-black sm:px-4 md:text-2xl">
                $HDAO token holders can unlock significant discounts, more info below.
                </p>
              </div>
            </div>

            <div class="mx-auto grid max-w-[1200px] grid-cols-1 gap-4 py-8 pb-20 lg:grid-cols-2">
              <div class="mt-19 flex flex-grow flex-col overflow-hidden rounded-lg bg-white shadow-lg">
                <div class="flex flex-col items-center space-y-2 bg-accent-purple p-10">
                  <span class="text-2xl font-bold uppercase text-white">Individual</span>
                  <div class="flex items-center blur-md">
                    <span class="text-3xl text-white">$</span>
                    <span class="text-7xl font-bold text-white">000</span>
                    <span class="text-5xl text-white">/mo</span>
                  </div>
                </div>
                <div class="p-10">
                  <ul role="list" class="w-fit space-y-4 px-4 py-6 text-left text-base text-gray-600 md:px-12 md:text-justify">
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">⚡</span>
                      <span class="font-bold">50 tasks / energy credits monthly</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">⏰</span>
                      <span class="font-bold">25 Assistant working hours at your disposal</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>NFT-based subscription</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>Use, share, or loan</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>Wallet sign-in to PA app</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>No personal info needed</span>
                    </li>
                    <li class="space-x-2">
                      <span className="italic">
                        Pocket Assistants are from the world's underserved communities. Your subscription fees support these people in this new web3 opportunity, as well as the humanDAO. Thank you{" "}
                        <span className="inline-block max-w-[16px]">
                          <img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/325/saluting-face_1fae1.png"></img>
                        </span>
                      </span>
                    </li>
                    {/* <li class="space-x-2">
                    <span class="font-semibold font-inter text-purple-500">🪙</span>
                    <span>$3000 of $HDAO tokens (at time of purchase)</span>
                  </li>
                  <hr className="border-2"></hr>
                  <h3 className="font-bold">50% Lifetime Discount</h3>
                  <li class="space-x-2">
                    <span class="font-semibold font-inter text-purple-500">🔒</span>
                    <span>Unlocked after six months</span>
                  </li>
                  <li class="space-x-2">
                    <span class="font-semibold font-inter text-purple-500">💯</span>
                    <span>Fully transferrable NFT - keep it, rent it, share it, or sell it</span>
                  </li> */}
                  </ul>
                </div>
                <div class="justfy-center flex px-10 pb-10">
                  <button class="flex h-12 w-full items-center justify-center rounded-lg bg-gray-200 px-6 text-sm uppercase hover:disabled">Coming soon</button>
                </div>
              </div>
              <div class="mt-19 flex flex-grow flex-col overflow-hidden rounded-lg bg-white shadow-lg">
                <div class="flex flex-col items-center space-y-2 bg-accent-purple p-10">
                  <span class="text-2xl font-bold uppercase text-white">Team/Family</span>
                  <div class="flex items-center blur-md">
                    <span class="text-3xl text-white">$</span>
                    <span class="text-7xl font-bold text-white">000</span>
                    <span class="text-5xl text-white">/mo</span>
                  </div>
                </div>
                <div class="p-10">
                  <ul role="list" class="w-fit space-y-4 px-4 py-6 text-left text-base text-gray-600 md:px-12 md:text-justify">
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">⚡</span>
                      <span class="font-bold">100 tasks / energy credits monthly</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">⏰</span>
                      <span class="font-bold">50 Assistant working hours at your disposal</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>NFT-based subscription</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>Use, share, or loan</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>Wallet sign-in to PA app</span>
                    </li>
                    <li class="space-x-2">
                      <span class="font-inter font-semibold text-purple-500">✓</span>
                      <span>No personal info needed</span>
                    </li>
                    <li class="space-x-2">
                      <span className="italic">
                        Pocket Assistants are from the world's underserved communities. Your subscription fees support these people in this new web3 opportunity, as well as the humanDAO. Thank you{" "}
                        <span className="inline-block max-w-[16px]">
                          <img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/325/saluting-face_1fae1.png"></img>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="justfy-center flex px-10 pb-10">
                  <button class="flex h-12 w-full items-center justify-center rounded-lg bg-gray-200 px-6 text-sm uppercase hover:disabled">Coming soon</button>
                </div>
              </div>
            </div>

            <div className="mx-auto grid max-w-[1200px]">
            <h1 className="px-12 text-center font-gilroy text-5xl tracking-wide text-[#dd5b88] drop-shadow-sm sm:px-0 md:text-7xl">$HDAO Discount</h1>
            <div class="container mx-auto flex flex-wrap py-24">
              {/* <div class="mb-10 w-full shadow-md lg:mb-0 lg:w-1/2 order-2 lg:order-1 py-4 md:py-0">
                <img alt="feature" class="h-full w-full rounded-xl" src="https://res.cloudinary.com/daljbo1q0/image/upload/v1669273648/hdao-nft/Example_dycvfs.png"></img>
              </div> */}
              <div class="-mb-4 flex flex-col md:flex-row text-center mx-auto lg:space-x-4">
              <div class="mb-10 flex flex-col items-center lg:items-start bg-white w-full lg:w-1/2 px-6 py-6 rounded-lg shadow-md">
                <div class="mb-5 inline-flex h-20 w-20 flex-shrink-0 mx-auto items-center justify-center text-accent-purple">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
                      />
                    </svg>
                  </div>
                  <div class="flex-grow">
                    <p class="text-lg font-medium font-inter leading-relaxed">Addresses holding the equivalent of 3 months of subscription fees in $HDAO are eligible for a 50% discount.</p>
                  </div>
                </div>
                <div class="mb-10 flex flex-col items-center lg:items-start bg-white w-full lg:w-1/2 px-6 py-6 rounded-lg shadow-md">
                <div class="mb-5 inline-flex h-20 w-20 flex-shrink-0 mx-auto items-center justify-center text-accent-purple">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div class="flex-grow">
                  <p class="text-lg font-medium font-inter leading-relaxed">You are elgible for this discount while you hold the required $HDAO.</p>
                  </div>
                </div>
                <div class="mb-10 flex flex-col items-center lg:items-start bg-white w-full lg:w-1/2 px-6 py-6 rounded-lg shadow-md">
                <div class="mb-5 inline-flex h-20 w-20 flex-shrink-0 mx-auto items-center justify-center text-accent-purple">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-12 w-12">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                      />
                    </svg>
                  </div>
                  <div class="flex-grow">
                    <p class="text-lg font-medium font-inter leading-relaxed">Holding $HDAO tokens allows for other perks like voting on proposals, or staking to earn more.</p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Pricing;
