import React from 'react';
import './App.css';
import { Routes, Route, Link } from 'react-router-dom';
import Content from './pages/content';
import Pricing from './pages/pricing';
import Learn from './pages/learnmore';
import Trial from './pages/trial';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Content />} />
        {/* <Route path="test" element={<Test />} /> */}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/learnmore" element={<Learn />} />
        <Route path="/trial" element={<Trial />} />
        {/* <Route path="moonbirds" element={<Moonbirds />} /> */}
        {/* <Route path="bff" element={<BFF />} /> */}
      </Routes>
    </div>
  );
}

export default App;
